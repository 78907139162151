<template>
    <el-container>
        <!-- 顶部菜单 -->
        <el-header height="60px" class="header">
            <div class="header-container">
                <!-- 左侧 Logo -->
                <div class="logo" @click="navigateTo('/login')">
                    <img src="../assets/images/headerLogo.png" alt="Logo" class="logo-img" />
                </div>
                <!-- 右侧菜单 -->
                <el-menu mode="horizontal" :default-active="getActiveMenu" background-color="#ff0000" text-color="#fff"
                    active-text-color="#fff" class="menu">
                    <el-menu-item index="/" @click="navigateTo('/')">首页</el-menu-item>
                    <el-menu-item index="/mainInfo" @click="navigateTo('/mainInfo')">走进菁商会</el-menu-item>
                    <el-menu-item index="/branches" @click="navigateTo('/mainInfo/branches')">各地分会</el-menu-item>
                    <el-menu-item index="/fund" @click="navigateTo('/mainInfo/fund')">公益基金</el-menu-item>
                    <el-menu-item index="/create" @click="navigateTo('/mainInfo/create?type=create&title=创办分会')">创办分会</el-menu-item>
                    <el-menu-item index="/contact" @click="navigateTo('/mainInfo/contact')">联系我们</el-menu-item>
                </el-menu>
            </div>
        </el-header>

        <el-main>
            <router-view></router-view>
        </el-main>
        <!-- 底部信息 -->
        <el-footer>
            <FooterComponent />
        </el-footer>
    </el-container>
</template>

<script>
import FooterComponent from '../components/footer/index.vue';

export default {
    components: {
        FooterComponent,
    },
    computed: {
        // 计算当前需要高亮的菜单项
        getActiveMenu() {
            const path = this.$route.path;

            // 定义一个映射关系，用于特殊子路由高亮不同的菜单项
            const menuMapping = {
                '/mainInfo/branches': '/branches',
                '/mainInfo/contact': '/contact',
                '/mainInfo/create': '/create',
                '/mainInfo/fund': '/fund',
            };

            console.log(path)
            // 如果当前路径在映射表中，返回相应的菜单项
            if (menuMapping[path]) {
                return menuMapping[path];
            }

            // 检查当前路由的匹配数组是否包含 '/mainInfo'
            const matched = this.$route.matched;
            if (matched.some(record => record.path === '/mainInfo')) {
                return '/mainInfo'; // 高亮 "走进菁商会"
            }
            // 默认情况下返回当前路径
            return path;
        },
    },
    methods: {
        navigateTo(path) {
            this.activeMenu = path;
            this.$router.push(path);
        },
    },
};
</script>

<style lang="scss">
.el-menu-item.is-active {
    background-color: #000 !important;
}

* {
    user-select: none;
    /* 禁止页面上所有元素的文本选中 */
}

/* 顶部菜单样式 */
.header {
    background-color: $primary-color;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .header-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;

        // 左侧 Logo 区域
        .logo {
            display: flex;
            align-items: center;
            cursor: pointer;

            .logo-img {
                height: 50px;
                margin-right: 10px;
            }
        }

        // 右侧菜单样式
        .menu {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
