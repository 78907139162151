import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css'
import router from './router'; // 引入路由
import globalMixin from './mixins/globalMixin';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

const app = createApp(App);
app.mixin(globalMixin);
app.use(router);
app.use(ElementPlus, { locale: zhCn });
app.mount('#app')
