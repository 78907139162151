<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 可以放置一些全局样式 */
</style>