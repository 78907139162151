<template>
    <el-main>
        <router-view></router-view>
    </el-main>
</template>

<script>
export default {
    name: "AuthLayout",
};
</script>

<style scoped>
/* 可以添加登录页面特有的样式 */
</style>