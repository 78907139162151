import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import AuthLayout from '../layouts/AuthLayout.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: '', name: 'HomePage', component: () => import('../views/home/HomePage.vue') },
      {
        path: '/mainInfo',
        component: () => import('../views/mainInfo/MainInfo.vue'),
        children: [

          {
            path: 'Introduce',
            name: 'Introduce',
            component: () => import('../views/webView')
          },
          {
            path: 'branches',
            name: 'Branches',
            component: () => import('../views/Branches'),
            children: [
              {
                path: 'search',
                name: 'BranchesSearch',
                component: () => import('../views/Branches/search.vue')
              },
              {
                path: 'detail',
                name: 'BranchesDetail',
                component: () => import('../views/Branches/detail.vue')
              },
              {
                path: 'noData',
                name: 'BranchesNoData',
                component: () => import('../views/Branches/noData.vue')
              }
            ]
          },
          {
            path: 'fund',
            name: 'Fund',
            component: () => import('../views/fund')
          },
          {
            path: 'contact',
            name: 'Contact',
            component: () => import('../views/contact')
          },
          {
            path: 'infoAI',
            name: 'infoAI',
            component: () => import('../views/webView')
          },
          {
            path: 'gallery',
            name: 'gallery',
            component: () => import('../views/webView')
          },
          {
            path: 'events',
            name: 'events',
            component: () => import('../views/webView')
          },
          {
            path: 'community',
            name: 'community',
            component: () => import('../views/webView')
          },
          {
            path: 'academy',
            name: 'academy',
            component: () => import('../views/webView')
          },
          {
            path: 'join',
            name: 'join',
            component: () => import('../views/webView')
          },
          {
            path: 'attend',
            name: 'attend',
            component: () => import('../views/webView')
          },
          {
            path: 'create',
            name: 'create',
            component: () => import('../views/webView')
          },
          {
            path: 'rewards',
            name: 'rewards',
            component: () => import('../views/webView')
          },
          {
            path: 'publicFund',
            name: 'publicFund',
            component: () => import('../views/webView')
          }

          // {
          //   path: 'contact',
          //   name: 'AboutContact',
          //   component: () => import('../views/about/Contact.vue')
          // }
        ]
      },
    ],
  },
  {
    path: '/login',
    component: AuthLayout,
    redirect: '/login/index',
    children: [
      { path: 'index', name: 'Login', component: () => import('../views/login/index.vue') },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
