<template>
    <el-footer>
        <div class="footer">
            <el-row type="flex" justify="center">
                <!-- 左侧公司信息 -->
                <el-col :xs="24" :sm="24" :md="10" :lg="10" class="footer-left-links">
                    <el-row class="footer-section">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" class="title">
                            <span>菁商会</span>
                            <el-divider style="background-color: #fff;" direction='vertical' />
                            <span>环球商务精准对接平台</span>
                            <span>（社交人脉/生意引荐） </span>
                        </el-col>
                        <el-col :xs="20" :sm="20" :md="20" :lg="20" class="info" type="flex" justify="center">
                            <el-col :xs="24" :sm="24" :md="24" :lg="24" class="info" type="flex" justify="center">
                                <!-- 遍历 infoList，按组渲染 -->
                                <div v-for="(group, groupIndex) in groupedInfoList" :key="groupIndex"
                                    :class="['info-text', groupIndex > 0 ? 'side' : '']">
                                    <div v-for="(item, index) in group" :key="index" class="info-item">
                                        <el-icon :size="20">
                                            <component :is="item.img" />
                                        </el-icon>
                                        <span>{{ item.info }}</span>
                                    </div>
                                </div>
                            </el-col>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" class="qrcode-view">
                            <el-row type="flex" justify="center">
                                <el-col :xs="8" :sm="8" :md="8" :lg="8">
                                    <div class="qrcode">
                                        <img src="../../assets/images/mp_app_qrcode.jpg" />
                                        <span>公众号</span>
                                    </div>
                                </el-col>
                                <el-col :xs="8" :sm="8" :md="8" :lg="8">
                                    <div class="qrcode">
                                        <img src="../../assets/images/AI_qrcode.jpg" />
                                        <span>AI智能名片社交</span>
                                    </div>
                                </el-col>
                                <el-col :xs="8" :sm="8" :md="8" :lg="8">
                                    <div class="qrcode">
                                        <img src="../../assets/images/h5_qrcode.png" />
                                        <span>在线服务</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>

                </el-col>
                <el-divider style="background-color: #fff; margin-top: 20px; height: 18.63rem;" direction='vertical'
                    class="hidden-md-and-down hidden-sm-and-down" />
                <!-- 右边部分：服务、公益 -->
                <el-col :xs="24" :sm="24" :md="10" :lg="10" class="footer-right-links">

                    <el-row class="footer-section">
                        <el-col :xs="12" :sm="12" :md="6" :lg="6">
                            <h2>服务</h2>
                            <ul>
                                <li>菁商会介绍</li>
                                <li>各地分会</li>
                                <li>AI名片社交</li>
                                <li>商务活动</li>
                                <li>图片展示</li>
                                <li>菁商社群</li>
                            </ul>
                        </el-col>
                        <el-col :xs="12" :sm="12" :md="6" :lg="6">
                            <h2 style="opacity: 0;">1</h2>
                            <ul>
                                <li>我要入会</li>
                                <li>我要参会</li>
                                <li>我要创会</li>
                                <li>菁商学院</li>
                                <li>奖励基金</li>
                            </ul>
                        </el-col>
                        <el-col :xs="12" :sm="12" :md="6" :lg="6">
                            <h2>公益</h2>
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <ul>
                                    <li>捐助</li>
                                    <li>资金融结余</li>
                                    <li>收支公示</li>
                                </ul>
                                <img src="../../assets/images/foot_img.png" alt="公益图片"
                                    style="width: 96px; height: 96px;">
                            </div>
                        </el-col>
                        <el-col :xs="12" :sm="12" :md="6" :lg="6">
                            <h2>网络</h2>
                            <ul>
                                <li>美国</li>
                                <li>欧洲</li>
                                <li>澳洲</li>
                                <li>新加坡</li>
                                <li>马来西亚</li>
                                <li>中东</li>
                                <li>更多......</li>
                            </ul>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>

    </el-footer>
</template>
<script>

import { Location, Phone, Iphone, ChatRound, Message } from '@element-plus/icons-vue'
export default {


    name: 'footerComponent',
    components: {
        Location,
        Phone,
        Iphone,
        Message,
        ChatRound
    },
    computed: {
        groupedInfoList() {
            return [
                [this.infoList[0]], // 第一行：地址
                [this.infoList[1], this.infoList[2]], // 第二行：电话和手机
                [this.infoList[3], this.infoList[4]], // 第三行：邮箱和微信
            ];
        },
    },
    data() {
        return {
            infoList: [
                {
                    img: 'Location',
                    info: '地址：广州市越秀区花园酒店花园大厦618室',
                },
                {
                    img: 'Phone',
                    info: '电话：020-2818 5222',
                },
                {
                    img: 'Iphone',
                    info: '手机：13711195558',
                },
                {
                    img: 'Message',
                    info: '邮箱：ebc1688@126.com',
                },
                {
                    img: 'ChatRound',
                    info: 'Jason'
                },
            ],

        }
    }
}
</script>
<style lang="scss" scoped>
.el-footer {
    background-color: #a6262b;
}

.footer {
    color: #ffffff;
    padding-top: 40px;
    text-align: center;
    min-height: 25rem;
}

.footer-qr-codes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.footer-left-links .footer-section {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 0.7rem;

        span:nth-of-type(1) {
            font-size: var(--fs-xll);
            font-weight: 400;
            /* padding-right: 10px; */

        }

        span:nth-of-type(2) {
            /* padding-left: 10px; */
            font-size: var(--fs-lg);
            font-weight: 400;
        }
    }

    .info {
        text-align: right;

        .side {
            display: flex;
            justify-content: space-between;
        }

        .info-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .info-item {
            display: flex;
            align-items: center;
            margin-right: 15px;
            /* 给每个元素间增加一些间距 */
        }
    }

    .qrcode-view {
        margin-top: 30px;
        text-align: center;

        .qrcode {
            display: flex;
            flex-direction: column;
            justify-content: center;

            align-items: center;

            img {
                width: 118px;
                height: 118px;
            }

            span {
                margin-top: 8px;
            }
        }
    }
}

.footer-right-links .footer-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    h2 {
        font-size: var(--fs-xl);
    }

    li {
        font-family: $font-family;
        font-size: var(--fs-sm);
        height: 1.25rem;
        line-height: 1.25rem;
        margin-bottom: 0.88rem;
    }
}

.qr-code {
    width: 80px;
    height: 80px;
}

ul {
    list-style: none;
    padding: 0;
}

h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

h2 {
    font-size: 1rem;
    font-weight: bold;
}

li {
    margin-bottom: 5px;
    text-align: center;
    /* 确保每行居中 */
}

/* 响应式调整 */
@media (max-width: 600px) {

    .footer-right-links {
        text-align: center;
    }

    .footer-right-links .footer-section {
        flex-direction: column;
        align-items: center;
    }
}
</style>
