// globalMixin.js
export default {
    data() {
        return {
            currentWindowWidth: window.innerWidth // 初始化窗口宽度
        };
    },
    computed: {
        dynamicPaddingLeftValue() {
            const calculatedWidth = (this.currentWindowWidth - 2400) * 0.39;
            return this.currentWindowWidth > 2400 ? `${calculatedWidth}px` : '0px';
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateCurrentWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateCurrentWindowWidth);
    },
    methods: {
        updateCurrentWindowWidth() {
            this.currentWindowWidth = window.innerWidth; // 更新窗口宽度
        }
    }
};
